import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import { useSelector } from '../../../redux/store';
import get from 'lodash/get';
import noop from 'lodash/noop';
import Chip from '@mui/material/Chip';
import { useNavigate, useLocation } from 'react-router';
import { PATH_DASHBOARD } from '../../../routes/paths';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import GiftboxAnimation from '../../../components/GiftboxAnimation.json';
import Lottie from 'react-lottie';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { find, split, startCase } from 'lodash';
import { mobileNavConfig } from '../navbar/NavConfig';
import { categoryName } from 'src/constants/CategoryConstants';
// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const bookingData = useSelector((state) => state.verifyCashback);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: GiftboxAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const location = useLocation();

  const isCashbackPage = get(location, 'pathname') === '/app/cashback';

  const isCashbackAvailable = get(bookingData, 'cashbackStatus');

  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const navigate = useNavigate();

  const theme = useTheme();

  const handleNavigateToCashbackPage = () => {
    navigate(PATH_DASHBOARD.Cashback);
  };

  const pageName = split(get(location, 'pathname'), '/')?.at?.(-1);

  const ignoreLastPath = split(get(location, 'pathname'), '/')
    ?.slice?.(0, -1)
    ?.join?.('/');

  const isNoMainPage = !find(get(mobileNavConfig, '0.items'), (_item) => {
    return get(_item, 'path') === location.pathname;
  });

  const HeaderName = () => {
    if (pageName === get(mobileNavConfig, '0.items.0.title')) {
      return 'Home';
    } else if (ignoreLastPath === '/app/vendors' && Number(pageName)) {
      return categoryName(pageName);
    } else if (Number(pageName)) {
      return '';
    } else {
      return startCase(pageName);
    }
  };

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          backgroundColor: theme.palette.primary.main,
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          {/* {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />} */}

          {/* {!isDesktop && (
            <IconButtonAnimate
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: "text.primary" }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )} */}

          {/* <Searchbar /> */}
          {/* <Box sx={{ flexGrow: 1 }} /> */}

          <Stack
            direction="row"
            spacing={0.5}
            sx={{ display: 'flex', alignItems: 'center' }}>
            {isNoMainPage && (
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon sx={{ width: 25, height: 25, color: '#fff' }} />
              </IconButton>
            )}
            <Typography
              variant="h4"
              sx={{ color: '#fff', fontWeight: 'semibold' }}>
              {HeaderName()}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1.5 }}
            // maxWidth="5.5rem"
          >
            {isCashbackAvailable && (
              <Box
                onClick={() =>
                  !isCashbackPage && handleNavigateToCashbackPage()
                }>
                <Lottie options={defaultOptions} height={60} />
              </Box>
            )}
            <Logo isWhiteLogo />
            {/* <LanguagePopover />  */}
            {/* <NotificationsPopover /> */}
            {/* <ContactsPopover /> */}
            {/* <AccountPopover /> */}
          </Stack>
        </Box>
      </Toolbar>
    </RootStyle>
  );
}
