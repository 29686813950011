import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CardGiftCard from '@mui/icons-material/CardGiftcard';
import BorderColor from '@mui/icons-material/BorderColor';
import { useNavigate } from 'react-router';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

const CustomListItem = ({ icon, title, isLogout, onClick }) => {
  const theme = useTheme();

  if (isLogout) {
    return (
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={2}
        p={2}
        sx={{
          backgroundColor: theme.palette.error.lighter,
          borderRadius: 1,
        }}
        onClick={onClick}>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: theme.palette.error.main,
          }}>
          {title}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={2}
      p={2}
      sx={{ backgroundColor: '#F6F6F6', borderRadius: 1 }}
      onClick={onClick}>
      {icon}
      <Typography sx={{ fontSize: '18px' }}>{title}</Typography>
    </Stack>
  );
};

const Settings = () => {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();

  const { logout } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <Page title="Settings">
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ mb: 5, mt: 1 }}>
        <Stack spacing={2}>
          <Stack gap={3} p={0.5}>
            <CustomListItem
              icon={<AccountCircleOutlinedIcon />}
              title="Profile"
              onClick={() => {
                navigate(PATH_DASHBOARD.UserProfile);
              }}
            />
            <CustomListItem
              icon={<CardGiftCard />}
              title="Cashback"
              onClick={() => {
                navigate(PATH_DASHBOARD.Cashback);
              }}
            />
            <CustomListItem
              icon={<BorderColor />}
              title="Complaints"
              onClick={() => {
                navigate(PATH_DASHBOARD.UserAdminComplaint);
              }}
            />
            <CustomListItem title="Logout" isLogout onClick={handleLogout} />
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
};

export default Settings;
