import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import RouteConstants from '../constants/RouteConstants';
import Settings from 'src/pages/app/Settings';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={
        <LoadingScreen isDashboard={pathname.includes('/dashboard')} />
      }>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <Navigate
              to={`/${RouteConstants.ROOT_AUTH}/${RouteConstants.LOGIN}`}
              replace
            />
          ),
          index: true,
        },
      ],
    },
    {
      path: RouteConstants.ROOT_AUTH,
      children: [
        {
          path: RouteConstants.LOGIN,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: RouteConstants.REGISTER,
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: RouteConstants.TERMS_AND_CONDITIONS,
          element: (
            <GuestGuard>
              <TermsAndConditions />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password/:token', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: RouteConstants.ROOT_APP,
          children: [
            {
              element: <Navigate to={RouteConstants.VENDORS} replace />,
              index: true,
            },
            { path: RouteConstants.MESSAGES, element: <Messages /> },
            {
              path: `${RouteConstants.MESSAGES}/:conversationKey`,
              element: <Messages />,
            },
            { path: RouteConstants.VENDORS, element: <Vendors /> },
            { path: RouteConstants.CASHBACK, element: <Cashback /> },
            {
              path: RouteConstants.CASHBACK_HISTORY,
              element: <CashbackHistory />,
            },
            {
              path: `${RouteConstants.VENDORS}/:categoryId`,
              element: <VendorsList />,
            },
            {
              path: RouteConstants.SHORTLISTED_VENDORS,
              element: <ShortlistedVendorList />,
            },
            {
              path: RouteConstants.CURRENT_BOOKINGS,
              element: <CurrentBookings />,
            },
            {
              path: `${RouteConstants.VENDORS}/${RouteConstants.DETAILS}/:vendorId`,
              element: <VendorDetails />,
            },
            {
              path: RouteConstants.VENDORUSERCHAT,
              element: <VendorUserChat />,
            },
            {
              path: RouteConstants.USERADMINCOMPLAINT,
              element: <UserAdminComplaintWindow />,
            },
            {
              path: RouteConstants.BOOKING_HISTORY,
              element: <BookingHistory />,
            },
            {
              path: RouteConstants.VIDEOS,
              element: <VendorVideos />,
            },
            {
              path: RouteConstants.USER_PROFILE,
              element: <UserProfile />,
            },
            {
              path: RouteConstants.USER_REVIEWS,
              element: <UserReviews />,
            },
            {
              path: RouteConstants.SETTINGS,
              element: <Settings />,
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword'))
);
const TermsAndConditions = Loadable(
  lazy(() => import('../pages/auth/TermsAndConditions'))
);
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const VendorUserChat = Loadable(
  lazy(() => import('src/pages/app/VendorUserChat'))
);
const UserAdminComplaintWindow = Loadable(
  lazy(() => import('src/pages/app/UserAdminComplaintWindow'))
);
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

const Messages = Loadable(lazy(() => import('../pages/app/Messages')));
const Vendors = Loadable(lazy(() => import('../pages/app/Vendors')));
const Cashback = Loadable(lazy(() => import('../pages/app/Cashback')));
const CashbackHistory = Loadable(
  lazy(() => import('../pages/app/CashbackHistory'))
);
const VendorsList = Loadable(lazy(() => import('../pages/app/VendorsList')));
const ShortlistedVendorList = Loadable(
  lazy(() => import('../pages/app/ShortlistedVendorList'))
);
const CurrentBookings = Loadable(
  lazy(() => import('../pages/app/CurrentBookingList'))
);
const VendorDetails = Loadable(
  lazy(() => import('../pages/app/VendorDetails'))
);
const BookingHistory = Loadable(
  lazy(() => import('../pages/app/BookingHistory'))
);
const VendorVideos = Loadable(lazy(() => import('../pages/app/VendorVideos')));
const UserProfile = Loadable(lazy(() => import('../pages/app/UserProfile')));
const UserReviews = Loadable(lazy(() => import('../pages/app/UserReviews')));
