import APIService from '../APIService';
import ObjectToQueryParams from '../../utils/ObjectToQueryParams';

//------------------------------------------------------------------------------------------

const API = process.env.REACT_APP_REDROOM_API;
const RedroomService = {
  getVendorAvailability({ teamCount, vendorId, eventDate }) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/datecheck?teamCount=${teamCount}&vendorId=${vendorId}&eventDate=${eventDate}`,
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  payFullAmount(options) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/transaction`,
          method: 'POST',
          data: options,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  payAdvanceAmount(options) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/advance/transaction`,
          method: 'POST',
          data: options,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  payBalanceAmount(options = {}) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/balancepay/transaction`,
          method: 'POST',
          data: options,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  verifyBooking(txnId) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/verifyBooking?transactionId=${txnId}`,
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  verifyPayment(paymentId) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/payment/verifyPayment?transactionId=${paymentId}`,
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  cancelBooking(bookingId) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/cancel/${bookingId}`,
          method: 'PUT',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  getPaymentDetails(bookingId) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/verifyAmount?bookingId=${bookingId}`,
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  getAllApprovedVendors() {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/cashback`,
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  getBookingHistoryData(options) {
    const query = ObjectToQueryParams(options);
    let url = `${API}/api/v1/booking/userBooking-History${query}`;
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url,
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  getCurrentBookingData({ page, size, vendorStatus }) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/userCurrent-History?page=${page}&size=${size}&vendorStatus=${vendorStatus}`,
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  checkRefundStatus(options) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/booking/verifyrefund/transaction/user`,
          method: 'POST',
          data: options,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
};

export default RedroomService;
