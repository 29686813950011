export const venueId = '1';
export const foodId = '2';
export const invitationId = '3';
export const giftsId = '4';
export const photographyId = '5';
export const liveStreamingId = '6';
export const musicDanceAndArtsId = '7';
export const mehendiArtistId = '8';
export const makeupId = '9';
export const transportationAndTourId = '10';
export const planningAndDecorId = '11';

export const CATEGORIES = [
  'venue',
  'food',
  'invitation',
  'photography',
  'music, dance & arts',
  'mehendi artist',
  'makeup',
  'transportation & tour',
  'planning & decor',
];

export function categoryName(categoryId) {
  switch (categoryId) {
    case '1':
      return 'Venue';
    case '2':
      return 'Food';
    case '3':
      return 'Invitation';
    case '4':
      return 'Gifts';
    case '5':
      return 'Photography';
    case '6':
      return 'Live streaming';
    case '7':
      return 'Music, Dance & Arts';
    case '8':
      return 'Mehendi artist';
    case '9':
      return 'Makeup';
    case '10':
      return 'Transportation and Tour';
    default:
      return 'Planning & Decor';
  }
}
