import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import {
  RATING_FILTER_OPTIONS,
  RATING_OPTIONS,
} from 'src/constants/AppConstants';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  isStopFetch: false,
  vendorsList: [],
  page: 1,
  searchVendor: null,
  priceRange: [100, 1000000],
  sortByRating: get(RATING_OPTIONS, '0.value'),
  selectedVendorLocations: [],
  selectedRating: null,
};

const slice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateVendorsList(state, action) {
      const { vendorsList } = action.payload;
      state.isLoading = false;
      state.vendorsList = vendorsList;
    },
    updatePage(state, action) {
      const { page } = action.payload;
      state.isLoading = false;
      state.page = page;
    },
    updateIsStopFetch(state, action) {
      const { isStopFetch } = action.payload;
      state.isLoading = false;
      state.isStopFetch = isStopFetch;
    },
    updateSearchVendor(state, action) {
      const { searchVendor } = action.payload;
      state.isLoading = false;
      state.searchVendor = searchVendor;
    },
    updatePriceRange(state, action) {
      const { priceRange } = action.payload;
      state.isLoading = false;
      state.priceRange = priceRange;
    },
    updateFilter(state, action) {
      const { sortByRating } = action.payload;
      state.isLoading = false;
      state.sortByRating = sortByRating;
    },
    updateSelectedVendorLocations(state, action) {
      const { selectedVendorLocations } = action.payload;
      state.isLoading = false;
      state.selectedVendorLocations = selectedVendorLocations;
    },
    updateSelectedRating(state, action) {
      const { selectedRating } = action.payload;
      state.isLoading = false;
      state.selectedRating = selectedRating;
    },
    resetVendors(state) {
      state.isLoading = false;
      state.vendorsList = [];
      state.page = 1;
      state.isStopFetch = false;
      state.searchVendor = null;
      state.priceRange = [100, 1000000];
      state.sortByRating = get(RATING_OPTIONS, '0.value');
      state.selectedVendorLocations = [];
      state.selectedRating = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  updateVendorsList,
  updatePage,
  updateIsStopFetch,
  updateSearchVendor,
  updatePriceRange,
  updateFilter,
  updateSelectedVendorLocations,
  updateSelectedRating,
  resetVendors,
} = slice.actions;

// ----------------------------------------------------------------------
