import APIService from '../APIService';
import AppContants from '../../constants/AppContants';

const API = process.env.REACT_APP_CLIENT_API;

const AuthenticationService = {
  postSignInData(data) {
    return new Promise((resolve, reject) => {
      APIService.fetch(
        `${API}/api/v1/auth/signin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            source: AppContants.USER,
          },
          body: JSON.stringify(data),
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  postSignUpData(data) {
    return new Promise((resolve, reject) => {
      APIService.fetch(
        `${API}/api/v1/auth/signup`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
};

export default AuthenticationService;
