// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ROOT_AUTH: 'auth',
  LOGIN: 'login',
  REGISTER: 'register',
  ROOT_APP: 'app',
  MESSAGES: 'messages',
  CASHBACK: 'cashback',
  COMPLAINTS: 'complaints',
  VENDORS: 'vendors',
  BOOKED: 'booked',
  DETAILS: 'details',
  VENDORUSERCHAT: 'vendorsChat',
  USERADMINCOMPLAINT: 'adminComplaint',
  BOOKING_HISTORY: 'bookinghistory',
  CASHBACK_HISTORY: 'cashback_history',
  VIDEOS: 'videos',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  CURRENT_BOOKINGS: 'currentBookings',
  SHORTLISTED_VENDORS: 'shortlistedVendors',
  USER_PROFILE: 'Profile',
  USER_REVIEWS: 'userReviews',
  SETTINGS: 'Settings',
};
