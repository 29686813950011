import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useLocation, useNavigate } from 'react-router';
import { Box, useTheme } from '@mui/material';
import { find, get, map } from 'lodash';
import { mobileNavConfig } from './dashboard/navbar/NavConfig';
import { useState } from 'react';

const IconWrapper = ({ children, isActive }) => {
  const theme = useTheme();

  if (!isActive) {
    return (
      <Box
        sx={{
          width: 50,
          height: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {children}
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          borderTop: `4px solid ${theme.palette.primary.main}`,
          width: 70,
          position: 'relative',
          bottom: 12,
        }}
      />
      <Box
        sx={{
          backgroundColor: theme.palette.primary.lighter,
          borderRadius: '50%',
          width: 45,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mx: 'auto',
          mb: 0.5,
        }}>
        {children}
      </Box>
    </Box>
  );
};

function BottomNavigationComponent() {
  const navigate = useNavigate();
  const location = useLocation();

  const [activePath, setActivePath] = useState(
    get(mobileNavConfig, '0.items.0.path')
  );

  const handleChange = (_, newPath) => {
    navigate(newPath);
    setActivePath(newPath);
  };

  const isNoMainPage = !find(get(mobileNavConfig, '0.items'), (_item) => {
    return get(_item, 'path') === location.pathname;
  });

  return (
    <BottomNavigation
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: -45,
        left: '50%',
        zIndex: 9999,
        transform: 'translate(-50%, -50%)',
        height: 90,
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      }}
      value={isNoMainPage ? activePath : location.pathname}
      onChange={handleChange}
      showLabels>
      {map(get(mobileNavConfig, '0.items'), (_item) => {
        return (
          <BottomNavigationAction
            label={get(_item, 'title')}
            value={get(_item, 'path')}
            icon={
              <IconWrapper
                isActive={
                  get(_item, 'path') ===
                  (isNoMainPage ? activePath : location.pathname)
                }>
                {get(_item, 'icon')}
              </IconWrapper>
            }
          />
        );
      })}
    </BottomNavigation>
  );
}

export default BottomNavigationComponent;
