import get from 'lodash/get';
import ObjectStorage from './ObjectStorageService';

export default {
  isLoggedIn() {
    const data = ObjectStorage.getItem('accessToken', {});
    return !!data;
  },
  getToken() {
    const data = ObjectStorage.getItem('accessToken', {});
    return data;
  },
  getUserName() {
    const data = ObjectStorage.getItem('UserData', {});
    return get(data, 'username');
  },
  getUserData() {
    const data = ObjectStorage.getItem('UserData', {});
    return data;
  },
  getUserId() {
    const data = ObjectStorage.getItem('UserData', {});
    return get(data, 'id');
  },
  logout() {
    ObjectStorage.removeItem('accessToken');
    ObjectStorage.removeItem('UserData');
    window.location.reload();
  },
};
