import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import map from 'lodash/map';
import RedroomService from 'src/services/API/RedroomService';
import { dispatch } from '../store';

import { findIndex, forEach } from 'lodash';
import moment from 'moment';
import { CATEGORIES } from 'src/constants/CategoryConstants';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  cashbackStatus: false,
  allApprovedVendors: [],
};

const slice = createSlice({
  name: 'verifyCashback',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getSuccess(state, action) {
      const { allApprovedVendors, status } = action.payload;
      state.isLoading = false;
      state.cashbackStatus = status;
      state.allApprovedVendors = allApprovedVendors?.data || [];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function verifyCashback() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await RedroomService.getAllApprovedVendors();

      const allApprovedVendors = get(response, 'data') || [];

      const formatedResponse = map(allApprovedVendors, (item) => {
        item.bookingStatus = 'Booked';
        return item;
      });

      const vendorCategories = map(formatedResponse, (_C) => {
        return _C.category;
      });

      const vendorCategoriesWithoutDuplicate = new Set(vendorCategories);

      const vendorCategoriesWithoutDuplicateArray = [
        ...vendorCategoriesWithoutDuplicate,
      ];

      const status =
        vendorCategoriesWithoutDuplicateArray.length === CATEGORIES.length;

      //----------------------------Remove Duplicate Categories base on Date - start------------------------------------
      const filterDataByDate = [];
      forEach(allApprovedVendors, (_item) => {
        const findDataIndex = findIndex(filterDataByDate, (_list) => {
          return get(_list, 'category') === get(_item, 'category');
        });

        if (findDataIndex >= 0) {
          var isBefore = moment(get(_item, 'createdAt')).isBefore(
            get(filterDataByDate, `${findDataIndex}.createdAt`)
          );

          if (isBefore) {
            filterDataByDate.splice(findDataIndex, 1, _item);
          }
        } else {
          filterDataByDate.push(_item);
        }
      });
      //-------------------------Remove Duplicate Categories base on Date - end---------------------------------------

      dispatch(
        slice.actions.getSuccess({
          allApprovedVendors: { data: filterDataByDate },
          status,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
