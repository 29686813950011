export const RATING_FILTER_OPTIONS = [
  { rating: 1, text: 'above1' },
  { rating: 2, text: 'above2' },
  { rating: 3, text: 'above3' },
  { rating: 4, text: 'above4' },
];

export const RATING_OPTIONS = [
  { value: 'high-low', label: 'High-low' },
  { value: 'low-high', label: 'Low-high' },
];

export const BOOKING_STATUS = {
  CANCELLED_STATUS: 'rejected',
  COMPLETED: 'completed',
  PENDING: 'pending',
  APPROVED: 'approved',
  REFUND_INITIATED: 'booking cancelled and refund initiated',
  REFUNDED: 'cancelled and refunded',
  CANCELLED: 'cancelled',
};

export const BOOKING_STATUS_TEXT = {
  CANCELLED_BY_USER: 'Cancelled by user',
  CANCELLED_BY_VENDOR: 'Cancelled by vendor',
  CANCELLED: 'Cancelled',
  REFUND_INITIATED: 'Refund initiated',
  REFUNDED: 'Refunded',
};

export const PAYMENT_STATUS = {
  PARTIALLY_PAID: 'partially paid',
  PAID: 'paid',
};

export const PAYMENT_STATUS_TEXT = {
  ADVANCE_PAYMENT: 'Advance payment',
  FULL_PAYMENT: 'Full payment',
};

export const VENDOR_VIDEO_HOSTNAMES = ["weddsetgo-dev", "youtu"]

export const YOUTUBE_HOSTNAMES = ["www.youtube.com", "youtu.be"];

export const YoutubeThumbnail = (videoId) => {
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};


