import CardGiftCard from '@mui/icons-material/CardGiftcard';
import TravelExplore from '@mui/icons-material/TravelExplore';
import BorderColor from '@mui/icons-material/BorderColor';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import Chat from '@mui/icons-material/Chat';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SettingsIcon from '@mui/icons-material/Settings';

import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

export const mobileNavConfig = [
  {
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.Vendors,
        icon: <HomeIcon sx={{ width: 30, height: 30 }} />,
      },
      {
        title: 'Bookings',
        path: PATH_DASHBOARD.currentBookings,
        icon: <PlaylistAddCheckCircleIcon sx={{ width: 30, height: 30 }} />,
      },
      {
        title: 'Shorlisted',
        path: PATH_DASHBOARD.shortlistedVendors,
        icon: <FavoriteIcon sx={{ width: 30, height: 30 }} />,
      },
      { title: 'Chat', path: PATH_DASHBOARD.VendorUserChat, icon: <Chat /> },
      {
        title: 'Settings',
        path: PATH_DASHBOARD.Settings,
        icon: <SettingsIcon sx={{ width: 30, height: 30 }} />,
      },
    ],
  },
];

const navConfig = [
  {
    items: [
      {
        title: 'My profile',
        path: PATH_DASHBOARD.UserProfile,
        icon: <AccountCircleIcon />,
      },
      {
        title: 'Vendors',
        path: PATH_DASHBOARD.Vendors,
        icon: <TravelExplore />,
      },
      {
        title: 'Current bookings',
        path: PATH_DASHBOARD.currentBookings,
        icon: <PlaylistAddCheckCircleIcon />,
      },
      {
        title: 'Booking History',
        path: PATH_DASHBOARD.BookingHistory,
        icon: <ManageSearchIcon />,
      },
      {
        title: 'Shorlisted vendors',
        path: PATH_DASHBOARD.shortlistedVendors,
        icon: <RecentActorsIcon />,
      },
      {
        title: 'Cashback',
        path: PATH_DASHBOARD.Cashback,
        icon: <CardGiftCard />,
      },
      { title: 'Chat', path: PATH_DASHBOARD.VendorUserChat, icon: <Chat /> },
      {
        title: 'Complaints',
        path: PATH_DASHBOARD.UserAdminComplaint,
        icon: <BorderColor />,
      },
    ],
  },
];

export default navConfig;
