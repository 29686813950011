import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// pages
import Login from "../pages/auth/Login";
// components
import LoadingScreen from "../components/LoadingScreen";
import RouteConstants from "../constants/RouteConstants";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <Navigate to={`${RouteConstants.ROOT_AUTH}/${RouteConstants.LOGIN}`} />
  );
}

// const { pathname } = useLocation();

// const [requestedLocation, setRequestedLocation] = useState(null);

// if (!isInitialized) {
//   return <LoadingScreen />;
// }

// if (!isAuthenticated) {
//   if (pathname !== requestedLocation) {
//     setRequestedLocation(pathname);
//   }
//   return <Login />;
// }

// if (requestedLocation && pathname !== requestedLocation) {
//   setRequestedLocation(null);
//   return <Navigate to={requestedLocation} />;
// }
