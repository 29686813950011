import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import Router from './routes';
import ThemeProvider from './theme';
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import BridgeConstants from './constants/BridgeConstants';

// ----------------------------------------------------------------------

export default function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = window.customEventEmitter.subscribe(
      BridgeConstants.BACK_BUTTON,
      (nativeData) => {
        navigate(-1);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
