import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  height: 0,
};

const slice = createSlice({
  name: 'currentScrollListHeight',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    storeHeight(state, action) {
      const { height } = action.payload;
      state.isLoading = false;
      state.height = height;
    },
    resetScrollHeight(state) {
      state.isLoading = false;
      state.height = 0;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { storeHeight, resetScrollHeight } = slice.actions;

// ----------------------------------------------------------------------
