import RouteConstants from '../constants/RouteConstants';

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `/${root}/${sublink}`;
}

function nestedPath(root, firstSubLink, secondSubLink) {
  return `/${root}/${firstSubLink}/${secondSubLink}`;
}

const ROOTS_DASHBOARD = 'dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: RouteConstants.ROOT_AUTH,
  login: path(RouteConstants.ROOT_AUTH, RouteConstants.LOGIN),
  register: path(RouteConstants.ROOT_AUTH, RouteConstants.REGISTER),
  loginUnprotected: path(RouteConstants.ROOT_AUTH, 'login-unprotected'),
  registerUnprotected: path(RouteConstants.ROOT_AUTH, 'register-unprotected'),
  verify: path(RouteConstants.ROOT_AUTH, 'verify'),
  resetPassword: path(RouteConstants.ROOT_AUTH, 'reset-password'),
  newPassword: path(RouteConstants.ROOT_AUTH, 'new-password'),
  termsAndConditions: path(
    RouteConstants.ROOT_AUTH,
    RouteConstants.TERMS_AND_CONDITIONS
  ),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  Vendors: path(RouteConstants.ROOT_APP, RouteConstants.VENDORS),
  currentBookings: path(
    RouteConstants.ROOT_APP,
    RouteConstants.CURRENT_BOOKINGS
  ),
  shortlistedVendors: path(
    RouteConstants.ROOT_APP,
    RouteConstants.SHORTLISTED_VENDORS
  ),
  Cashback: path(RouteConstants.ROOT_APP, RouteConstants.CASHBACK),
  CashbackHistory: path(
    RouteConstants.ROOT_APP,
    RouteConstants.CASHBACK_HISTORY
  ),
  VendorUserChat: path(RouteConstants.ROOT_APP, RouteConstants.VENDORUSERCHAT),
  Settings: path(RouteConstants.ROOT_APP, RouteConstants.SETTINGS),
  UserAdminComplaint: path(
    RouteConstants.ROOT_APP,
    RouteConstants.USERADMINCOMPLAINT
  ),
  Messages: path(RouteConstants.ROOT_APP, RouteConstants.MESSAGES),
  Complaints: path(RouteConstants.ROOT_APP, RouteConstants.COMPLAINTS),
  details: nestedPath(
    RouteConstants.ROOT_APP,
    RouteConstants.VENDORS,
    'detials/:vendorId'
  ),
  BookingHistory: path(RouteConstants.ROOT_APP, RouteConstants.BOOKING_HISTORY),
  VendorVideos: path(RouteConstants.ROOT_APP, RouteConstants.VIDEOS),
  UserProfile: path(RouteConstants.ROOT_APP, RouteConstants.USER_PROFILE),
  UserReviews: path(RouteConstants.ROOT_APP, RouteConstants.USER_REVIEWS),
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
